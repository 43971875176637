<template>
  <div class="concept">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form class="search-form" :inline="true" size="small">
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysis.platform')"
        >
          <el-select
            style="width: 88px"
            v-model="currentPlatform"
            @change="changePlatform"
            filterable
          >
            <el-option
              v-for="item in platformList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 128px" v-model="currentDate" filterable>
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('common.brand')">
          <el-select style="width: 128px" v-model="currentBrandId" filterable>
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="search-form-gutter"
          :label="$t('analysisDetail.concept')"
        >
          <el-select style="width: 128px" v-model="currentValue" filterable>
            <el-option
              v-for="item in valueList"
              :key="item.value"
              :label="item.displayValue"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="download-btn">
        <el-button
          :disabled="!currentDate || !currentBrandId || !currentValue"
          size="small"
          class="excel-dl-btn"
          @click="downloadAll"
          icon="el-icon-download"
          >{{ $t("analysis.downloadAll") }}</el-button
        >
      </div>
    </div>
    <div class="data-content">
      <p v-if="loading" class="data-loading">
        <i class="el-icon-loading" />&nbsp;{{ $t("common.loading") }}...
      </p>
      <p v-else-if="!tableData || tableData.length === 0" class="data-loading">
        {{ $t("common.noMoreData") }}
      </p>
      <el-row v-else :gutter="10">
        <el-col
          class="data-block"
          :lg="{ span: '5-24' }"
          v-for="item in tableData"
          :key="item.key"
        >
          <div class="data-block-content">
            <el-image
              class="content-image"
              :src="item.url"
              :preview-src-list="[item.url]"
              fit="contain"
            />
            <div>
              <el-row class="content-line">
                <router-link
                  target="_blank"
                  :to="{
                    path: '/services/analysis/style-search',
                    query: { q: item.key, platform: currentPlatform },
                  }"
                  ><el-link type="primary" class="content-link">{{
                    $t("productDetail.detailSearch")
                  }}</el-link></router-link
                >
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{
                  $t("productDetail.newValue")
                }}</span>
                {{ item.value }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{
                  $t("productDetail.originalValue")
                }}</span>
                {{ item.originalValue }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{
                  $t("productDetail.item")
                }}</span>
                {{ item.item }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{
                  $t("productDetail.saleQty")
                }}</span>
                {{ item.saleQty }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{
                  $t("productDetail.listPrice")
                }}</span>
                ￥{{ item.listPrice }}
              </el-row>
              <el-row class="content-line">
                <span class="content-label">{{
                  $t("productDetail.salePrice")
                }}</span>
                ￥{{ item.salePrice }}
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <pagination
        v-show="total > 0"
        :total="total"
        :offset.sync="listQuery.offset"
        :limit.sync="listQuery.limit"
        layout="sizes, prev, pager, next, total "
        @pagination="fetchData"
      />
    </div>
  </div>
</template>

<script>
import {
  fetchRecords,
  fetchUserBrands,
  fetchDetailValues,
  fetchDetail,
} from "@/api/analysis";
import { downloadFileAuth } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";
import Guide from "@/components/Guide";

export default {
  components: {
    AiBreadcrumb,
    Pagination,
    Guide,
  },
  data() {
    return {
      currentPlatform: "tmall",
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      currentDate: null,
      currentBrandId: null,
      currentKey: "Concept",
      currentValue: null,
      dateList: [],
      brandList: [],
      valueList: [],
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      loading: false,
    };
  },
  created() {
    this.fetchDates();
  },
  methods: {
    fetchDates() {
      this.dateList = [];
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.currentPlatform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.dateList.push({
                value: e.date,
                label: e.linkDate,
              });
            });
          if (this.dateList && this.dateList.length > 0) {
            this.currentDate = this.dateList[0].value;
          }
        }
      });
    },
    fetchBrands() {
      this.currentBrandId = null;
      this.brandList = [];
      fetchUserBrands({
        dates: this.currentDate,
        platform: this.currentPlatform,
      }).then((response) => {
        if (response.success) {
          this.brandList =
            response.result && response.result.items
              ? response.result.items
              : [];
          if (this.brandList && this.brandList.length > 0) {
            this.currentBrandId = this.brandList[0].id;
          }
        }
      });
    },
    fetchValues() {
      this.currentValue = null;
      this.valueList = [];
      fetchDetailValues({
        date: this.currentDate,
        brandId: this.currentBrandId,
        key: this.currentKey,
      }).then((response) => {
        if (response.success) {
          this.valueList = response.result ? response.result : [];
          if (this.valueList.length > 0) {
            this.currentValue = this.valueList[0].value;
          }
        }
      });
    },
    fetchData() {
      if (!this.currentDate || !this.currentBrandId || !this.currentValue) {
        return;
      }
      this.tableData = [];
      this.total = 0;
      this.loading = true;
      fetchDetail({
        date: this.currentDate,
        brandId: this.currentBrandId,
        key: this.currentKey,
        value: this.currentValue,
        ...this.listQuery,
      })
        .then((response) => {
          if (response.success) {
            this.tableData = response.result.items || [];
            this.total = response.result.totalCount;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadAll() {
      let username =
        this.$store.getters.my && this.$store.getters.my.userName
          ? this.$store.getters.my.userName
          : "";
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      downloadFileAuth(
        aiApiRoot +
          "/v1/analysis/detail/export?date=" +
          this.currentDate +
          "&brandId=" +
          this.currentBrandId +
          "&key=" +
          this.currentKey +
          "&value=" +
          this.currentValue,
        "concept_" +
          this.currentDate +
          "_" +
          this.currentBrandId +
          "_" +
          this.currentKey +
          "_" +
          this.currentValue +
          "_" +
          username +
          ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    changePlatform() {
      this.tableData = [];
      this.total = 0;
      this.fetchDates();
    },
  },
  watch: {
    currentDate() {
      this.fetchBrands();
    },
    currentBrandId() {
      this.fetchValues();
    },
    currentValue() {
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.concept {
  .el-col-lg-5-24 {
    width: 1/5 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .search-form {
      float: left;
    }

    .download-btn {
      display: inline-block;
    }
  }

  .data-content {
    overflow: hidden;
    font-size: small;
    margin-bottom: -10px;

    .data-loading {
      text-align: center;
      font-size: 14px;
      color: #606266;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .data-block {
      margin-bottom: 10px;

      .data-block-content {
        background-color: #fff;
        padding-bottom: 5px;

        .content-image {
          width: 100%;
          height: 0;
          padding-bottom: 100%;

          /deep/ img {
            position: absolute;
            margin-bottom: 5px;
          }
        }

        .content-line {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: rgba(0, 0, 0, 0.65);
          padding-left: 10px;

          .content-label {
            color: #a9a9a9;
            margin-right: 5px;
            line-height: 27px;
          }

          .content-link {
            margin-top: 5px;
            margin-right: 5px;
            line-height: 27px;
          }
        }
      }
    }
  }
}
</style>